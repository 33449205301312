
.feature-modal iframe {
    display: block;
}

.faq_column {
    margin-bottom: 30px;
}

.team_img {
    overflow: hidden;
}

.team_img img {
    width: 100%;
}

.eurid-modal {
    margin-top: 80px;
}

.eurid-modal .modal-content {
    border-radius: 0;
    box-shadow: none;
    border: none;
}

.eurid-modal .modal-header {
    border: none;
}

.eurid-modal .modal-body {
    padding: 0 40px 40px;
}

.eurid-modal .author_info {
    text-align: left;
}

.eurid-modal .author-position {
    font-size: 22px;
    color: #908f8f;
}

.eurid-modal .modal-title {
    line-height: 36px;
    font-size: 30px;
}

.modal-description {
    clear: both;
    padding-top: 20px;
    font-size: 16px;
}

.team-image-modal {
    max-width: 120px;
    float: left;
    margin-right: 30px;
}

.team-image-modal img {
    border-radius: 50%;
    width: 100%;
}

@media (min-width: 768px) {
    .eurid-modal .modal-dialog {
        width: 550px;
    }
}

.search-result-title {
    text-align: center !important;
    font-size: 20px !important;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #ccc;
}

.search-results .highlighted {
    background-color: yellow;
}

#filter-registrar-list label {
    font-size: 14px;
}

.btn-search-registrar-placeholder a {
    margin-top: 20px;
}

body#error_500 #wrapper{
    background-color: rgb(114, 133, 173);
}
body#error_500 .footer-note {
    padding-top: 0;
}

body#error_500 .row-eq-height > * {
    margin-top: 0;
    margin-bottom: 0;
}

header .caret {
    background-clip: border-box;
    background-origin: padding-box;
    background: url(../images/bgr_select1.png) no-repeat scroll right center;
    background-color: inherit;
    background-size: auto auto;
    border: medium none !important;
    color: inherit;
    display: inline-block;
    height: 9px;
    margin-left: 0;
    width: 12px;
}

.form-control .btn {
    background-color: #fff;
    border: 1px solid #ccc;
}

/* PAGINATION */

.aldryn-newsblog-pagination {
    text-align: center;
}

.pagination a, .pagination li span {
    font-size: 14px;
}

ul.pagination > li > .active,
ul.pagination > li.active > span,
ul.pagination > li.active > a {
    background-color: #20325f;
    border-color: #20325f;
}

ul.pagination > li > a, ul.pagination > li > span {
    color: #5b89e1;
}

/* ALDRYN FAQ */

.hover_box_faq {
    border: 1px solid rgba(123, 123, 123, 0.81);
    background-color: white;
}
.hover_box_faq i {
    color : #20325f;
}
.hover_box_faq:hover {
    background-color: #20325f !important;
    color: white !important;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
.hover_box_faq:hover *{
    color: white !important;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

form[disabled]{
    cursor: not-allowed;
}

form[disabled] input, form[disabled] textarea {
    pointer-events: none !important;
    background-color: #eee;
}

form[disabled] button, form[disabled] label {
    pointer-events: none;
    opacity: 0.7;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del,
dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub,
sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form,
label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
}

:not(.modal):focus-visible {
    box-shadow: 0px 0px 0px 3px #39c;
    border-radius: 2px;
}

html {
    font-size: 14px;
}

body {
    color: #505559;
    font-family: 'Arial', sans-serif;
    font-weight: 300;
    font-size: 16px;
    position: relative;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #30333a;
}

.select2-container {
    width: 100%;
}

ul, ol {
    list-style: none;
}

img, fieldset {
    border: none;
}

a, a:hover {
    color: #1c4da1;
    text-decoration: underline;
}

a:focus {
    outline-offset: -200px !important;
}

a:hover, a:focus {
    outline: none !important;
}

a.btn, a.btn:hover {
    text-decoration: none;
}

a#clear-filter {
    text-decoration: none;
}

a[data-toggle="tab"] {
    text-decoration: none;
}

a.js-random-registrar-trigger {
    text-decoration: none;
}

a#advanced-search-trigger {
    text-decoration: none;
}

a.story_link {
    text-decoration: none;
    margin: 10px;
    display: inline-block;
}

a.hover_box_faq {
    text-decoration: none;
}

.toggle_content a {
    text-decoration: none;
}

article, aside, details, figcaption, figure, footer,
header, hgroup, main, nav, section, summary {
    display: block;
}

figure {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

embed, iframe,
object, video {
    max-width: 100%;
}

html {
    -webkit-text-size-adjust: none;
}

.aligncenter {
    clear: both;
    display: block;
    margin: 0 auto 20px;
}

.alignright {
    margin: 2px 0 10px 15px;
    display: inline;
    float: right;
}

.alignleft {
    margin: 2px 15px 10px 0;
    display: inline;
    float: left;
}


input, textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.row {
    padding: 0;
    margin: 0;
}

.row,
.col-lg-12,
.container-fluid {
    width: 100%;
    margin: 0;
}

html, body {
    height: 100%;
}

body p strong {
    color: #30333a;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.slider-title-homepage {
    background-color: #438CE2;
    color: #fff;
    font-size: 45px;
}

/*----------------------*/
/*     Page structure   */
/*----------------------*/

.center_content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

#wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.landing_page #wrapper {
    justify-content: space-between;
}

.search-results .well {
    background-color: rgba(245, 245, 245, 0.49);
    border: 0;
}

.search-result-page-link {
    font-size: 14px;
}

.search-result-title a {
    text-decoration: none;
}

.euridVirtualKeyboardIcon {
    position: absolute;
    margin-left: -30px;
    display: inline;
    margin-top: 5px;
}


.wordbreak {
    word-break: break-all;
}

.das_result {
    display: none;
}

.alert {
    border-radius: 0;
}

.landing_home {
    padding: 108px 10px 20px 10px;
    text-align: center;
}

.landing_home h1, .landing_home label[for="id_domain_name"] {
    font-size: 47px;
    color: #fff;
    font-weight: 300;
    padding-bottom: 30px;
    text-shadow: 2px 2px 1px rgba(123, 123, 123, 0.5);
}

.home_search {
    padding: 0;
}

.home_search .form-control {
    max-width: 695px;
    height: 57px;
    border-radius: 0;
    border-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset;
    margin: 0 auto 12px;
    display: inline-block;
    line-height: 39px;
    font-size: 18px;
}

.home_search .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.home_search input[type="submit"] {
    width: 120px;
    height: 57px;
    border: none;
    background: #5b89e1;
    border-radius: 0;
    line-height: 40px;
    padding: 0;
    margin: 0 auto;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
}

@media screen and (max-width: 899px) {
    .home_search input[type="submit"] {
        height: 40px;
    }

    .home_search .homepage-status-wrapper {
        max-width: 695px;
        margin-top: 10px;
    }


    .home_search .homepage-status-wrapper *:after {
        display: none;
    }
}

.home_three_column {
    overflow: hidden;
    padding: 38px 0 100px 0;
}

.home_three_column a {
    text-decoration: none;
}


.column_item {
    width: 33.33%;
    text-align: center;
    padding: 12px 10px 30px 10px;
    border-left: 1px solid #c3c5c5;
    float: left;
}

.column_item:first-child {
    border: none;
}

.column_item h1 {
    font-size: 28px;
    color: #fff;
    padding-bottom: 17px;
    margin-bottom: 0;
    font-weight: 300;
}

.column_item h1 a {
    color: #fff;
}

/*TODO: remove the following rule once the conversion to h1 has been done in cms*/
.column_item h3 {
    font-size: 28px;
    color: #fff;
    padding-bottom: 17px;
    margin-bottom: 0;
    font-weight: 300;
}


.column_item h3 a {
    color: #fff;
    text-shadow: 1px 1px 0 rgba(150, 150, 149, 0.80);
}

.column_item p {
    font-size: 22px;
    color: #fff;
    padding-bottom: 27px;
    text-shadow: 1px 1px 0 rgba(150, 150, 149, 0.80);
}

.get_started {
    display: inline-block;
    padding: 0 38px;
    height: 42px;
    background: #438ce2;
    font-size: 18px;
    color: #fff;
    text-align: center;
    border-radius: 0;
    line-height: 42px;
}

.get_started:hover {
    color: #fff;
    background: #407BC1;
}

#popup_content_area {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20000;
    background: url(../images/bgr_popup.png) repeat;
    display: none;
    padding: 90px 10px;
}

#popup_content_area_whois {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20000;
    background: url(../images/bgr_popup.png) repeat;
    display: none;
    padding: 90px 10px;
}

#pop_search {
    max-width: 820px;
    margin: 0 auto;
    position: relative;
}

#pop_search form {
    position: relative;
}

#pop_search label {
    font-weight: 300;
    width: 100%;
    font-size: 50px;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0 0 20px;
}

#pop_search input {
    width: calc(100% - 123px);
    height: 67px;
    background: url(../images/bgr_input.png) repeat;
    padding: 10px;
    font-size: 24px;
    font-family: 'Arial', sans-serif;
    border: none;
}

#pop_search input[type="submit"] {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 115px;
    height: 67px;
    cursor: pointer;
    text-align: center;
    font-size: 24px;
    color: #fff;
    border-radius: 0;
    background: #438ce2;
}

.close_pop {
    border: none;
    background: transparent url(../images/close_pop.png) no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
}

.plain_text_content {
    max-width: 910px;
    margin: 0 auto;
    padding: 50px 10px;
}

.entry {
    color: #505559;
}

.plain_text_title {
    font-weight: 300;
    padding: 0 0 25px;
    margin: 0 0 30px;
    font-size: 40px;
    color: #30333a;
    text-align: center;
    background: url(../images/bgr_title.png) no-repeat center bottom;
}

.entry p {
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 10px;
}


#slider {
    width: 100%;
    position: relative;
}

.slider_info_area {
    max-width: 1120px;
    padding: 0 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 95px;
    z-index: 20;
    margin: 0 auto 0 20%;
}

.slider_info {
    max-width: 600px;
    padding: 59px 60px 27px 25px;
    position: relative;
}

.slider_info h1 {
    font-size: 45px;
    font-weight: 300;
    color: #30333a;
}

.report {
    padding: 0 20px;
    height: 28px;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    border-radius: 0;
    background: #50b848;
    line-height: 28px;
    position: absolute;
    top: 29px;
    left: 25px;
}

.content_area {
    padding: 0 10px;
}

.content {
    max-width: 1100px;
    margin: 0 auto;
    padding: 28px 0 70px 0;
    overflow: hidden;
}

.section {
    padding: 50px 10px 50px 10px;
}

.section-gray {
    background: #f7f7f7;
}

.differ_width {
    max-width: 940px;
    padding-left: 10px;
    padding-right: 10px;
}

.content_left {
    width: 70%;
    float: left;
}

.feature_area {
    overflow: hidden;
}

.feature_box {
    margin-left: -28px;
    overflow: hidden;
}

.feature_column {
    width: 33.33%;
    padding: 0 0 0 28px;
    float: left;
}

.plain_text_content h2 {
    margin-top: 35px;
    margin-bottom: 15px;
}

.plain_text_content h1 {
    margin-bottom: 35px;
    font-weight: 300;
    line-height: 50px;
}

.news-content {
    padding: 5px 0 0;
}

.plain_text_content ul {
    list-style: circle;
    padding-left: 30px;
    margin-bottom: 20px;
}

.btn-lg, .btn-group-lg > .btn {
    border-radius: 0;
}


.left_side_title span {
    display: inline-block;
    min-height: 25px;
    line-height: 25px;
    padding-left: 14px;
    margin-left: 0;
    font-size: 16px;
    font-weight: 400;
}

.left_side_title h1 {
    display: inline;
    text-decoration: none;
    border-bottom: none;
    border-right: solid #000 2px;
    font-size: 25px;
    color: #30333a;
    font-weight: 300;
    padding-bottom: 2px;
    margin-bottom: 22px;
    padding-right: 15px;
}

.left_side_title hr {
    margin: 15px 0;
}

.left_side_title span a {
    display: inline-block;
    background: url(../images/bgr_arrow1.png) no-repeat right 7px;
    padding-right: 15px;
    color: #30333a;
    font-weight: 300;
    text-decoration: none;
    font-size: 20px;
}

.feature_info {
    padding: 9px 0 10px 0;
}

.feature_info a h2 {
    color: #30333a;
    font-size: 22px;
    font-weight: 300;
    padding-bottom: 6px;
    border-bottom: none;
}

.feature_info a {
    text-decoration: none;
}

.feature_info span {
    border: 0;
    margin: 0;
    padding: 0;
}

.feature_photo {
    position: relative;
}

.feature_photo span.report {
    top: inherit;
    bottom: 16px;
    left: 0;
    border: 0;
    margin: 0;
}

.report.press-release {
    background: #5b89e1;
}

.report.news {
    background: #20325f;
}

.feature_info h2 a {
    color: #30333a;
    font-size: 20px;
}

.feature_info span {
    display: inline-block;
    color: #9b9b9b;
    font-size: 14px;
    padding-bottom: 2px;
}

.feature_info p {
    font-size: 14px;
    color: #505559;
    padding-bottom: 5px;
    word-break: break-word;
}

.other_news {
    padding: 0
}

.other_news .feature_info {
    min-height: inherit
}

.other_news ul {
    padding-bottom: 40px;
}

.other_news ul li {
    color: #747171;
    font-size: 16px;
    padding-bottom: 7px;
    position: relative;
}

.other_news ul li span {
    display: inline-block;
}

.feature_info h6 {
    font-size: 18px;
    color: #30333a;
}

.feature_info h6 a {
    color: #30333a;
}

.other_news ul li a {
    color: #30333a;
    text-decoration: underline;
}

.sidebar h2 {
    padding-bottom: 10px;
}

.news_post {
    position: relative;
    padding-left: 66px;
    padding-bottom: 33px;
    min-height: 60px;
}

.news_post:last-child {
    background: none;
    padding-bottom: 0;
}

.news_post span {
    width: 50px;
    height: 50px;
    background: #5b89e1;
    padding: 10px 0 0 0;
    display: block;
    text-align: center;
    color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 300;
    font-size: 15px;
    line-height: 14px;
}

.news_post span small {
    display: block;
    font-size: 13px;
}

.news_post h4 {
    color: #010101;
    font-size: 21px;
    font-weight: 300;
    padding-bottom: 2px;
}

.news_post h4 a {
    color: #010101;
}

.news_post ul li {
    font-size: 13px;
    color: #595858;
    /*padding-left: 17px;*/
}

.news_post ul li a, .news_post ul li i {
    color: #595858
}

.news_post ul li i {
    padding-right: 8px;
}

.news_post ul li i.fa-flip-horizontal {
    padding-left: 5px;
    padding-right: 0;
}

.team_management .section {
    padding-left: 25px;
    padding-right: 25px;
}

@media screen and (max-width: 767px) {
    .news_post {
        padding-left: inherit;
    }

    .news_post span {
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .news_post ul {
        display: -moz-inline-stack;
        display: inline-block;
        zoom: 1;
        text-align: left;
    }

    .contact_area .section, .team_management .section {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.plain_text_content h3 {
    font-weight: 300;
    font-size: 24px;
    padding-bottom: 20px;
    text-align: left;
    margin-top: 20px;
}

.main_container {
    max-width: 927px;
    padding: 0 10px;
    margin: 0 auto;
}

.entry h4,
.main_container h4 {
    font-size: 28px;
    text-align: center;
    padding-bottom: 40px;
    max-width: 840px;
    margin: 0 auto;
    line-height: 36px;
}

.content_search {
    max-width: 647px;
    margin: 0 auto;
    position: relative;
    min-height: 48px;
    padding: 0 190px 20px 0;
}

.content_search .form-control {
    height: 48px;
    border-radius: 0;
    border-color: #b2b2b2;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset
}

.content_search .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.content_search input[type="submit"]:hover {
    background: #41507b
}

.content_search input[type="submit"] {
    width: 177px;
    height: 48px;
    background: #56658f;
    border: none;
    cursor: pointer;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 20px;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
}

hr {
    border: none;
    margin: 0 0 30px 0;
    height: 1px;
    outline: none;
    background: #cccccc;
}

.toggle_content_area {
    padding: 0
}

.toggle_content {
    padding-bottom: 0;
}

.toggle_view {
    display: none;
    padding: 0 0 20px 43px;
}

.toggle_view p {
    padding-bottom: 0
}

.toggle_content_open {
    display: block;
}


.toggle_content > a[data-toggle="collapse"] {
    font-size: 18px;
    color: #1c4da1;;
    cursor: pointer;
    text-align: left;
    padding-bottom: 10px;
    line-height: 30px;
    font-weight: normal;
    border-bottom: none;
}

.toggle_content a:not(.collapsed) + div.content_toggle_content {
    padding: 20px 0 20px 43px !important;
}

.toggle_content a.collapsed + div.content_toggle_content {
    padding: 20px 0 10px 43px !important;
}

.toggle_content > a[data-toggle="collapse"] span:not(.fa-stack) {
    border-bottom: 1px solid #b2afaf;
    display: inline-block;
    padding-bottom: 15px;
    width: calc(100% - 55px);
    color: #30333a;
    font-size: 18px;
    margin-top: 20px;
}

.toggle_content > a[data-toggle="collapse"] span.fa-stack {
    color: #3AB8DD;
    font-size: 14px;
    margin-right: 7px;
}


#nav_your_eu .content_box .btn-eurid.btn-primary {
    margin-bottom: 12px;
}

#nav_your_eu .content_box p {
    font-size: 16px;
    text-align: center;
}

.toggle_content h2.icon_change {
    background-position: 0 2px;
}

.toggle_content h2.icon_change span {
    border: none;
}

.toggle_content h2 span {
    border-bottom: 1px solid #b2afaf;
    display: block;
    padding-bottom: 15px;
}

.toggle_view ul li {
    font-size: 16px;
    color: #30333a;
    padding-bottom: 0;
}

.faq_three_column {
    overflow: hidden;
}

.faq_column_holder {
    overflow: hidden;
    margin-left: -34px;
}

.faq_column {
    padding-left: 34px;
    width: 33.33%;
    float: left;
    text-align: center;
}

.faq_column_bg {
    background: #ebedf5;
    padding: 43px 10px 38px 10px;
    min-height: 213px;
}

.faq_column_bg2 {
    background: #f0f3e6
}

.faq_column_bg3 {
    background: #e6e8ff
}

.faq_column h6 {
    color: #30333a;
    font-size: 27px;
    font-weight: 400;
    padding-bottom: 31px;
}

.faq_column p {
    font-size: 16px;
    line-height: normal;
    max-width: 252px;
    margin: 0 auto;
}

.full_width_content {
    max-width: 1220px;
}

.faq_column .btn-eurid.btn-primary {
    width: 172px;
    margin: 0 auto;
    font-size: 19px;
}

#banner {
    position: relative;
}

#banner::before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 200;
    height: 228px
}

.banner_info_area {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px;
    width: 100%;
    height: 100%;
    z-index: 900;
}

.banner_info {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    position: relative;
}

.banner_top {
    overflow: hidden;
    padding: 16px 0 0 0;
}

.banner_logo {
    float: left;
}

.banner_info_btm {
    width: 100%;
    max-width: 1080px;
    right: 0;
    left: 0;
    text-align: center;
    margin: 10% auto 0;
}

.banner_info_btm div.banner_title {
    font-size: 48px;
    font-weight: 300;
    color: #fff;
}

.banner_info_btm p {
    font-size: 22px;
    color: #fff;
    font-weight: 300;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.gray_bg {
    background: #f8f8f8;
    padding: 0 10px;
}

.white_bg {
    background: #fff;
    padding: 0 10px;
}

.content_box {
    max-width: 1100px;
    margin: 0 auto;
    padding: 50px 0 50px 0;
}

.content_box p,
.content_box ul li {
    font-size: 18px;
    color: #505559;
}

.content_box ul,
.content_box p {
    padding-bottom: 32px
}

.content_box .btn-eurid.btn-primary {
    width: 172px;
    margin: 0 auto;
    display: block;
}

.content_box > span {
    display: block;
    text-align: center;
    font-size: 14px;
    padding-top: 30px;
    color: #30333a;
}

.content_box > span a {
    color: #30333a;
    text-decoration: underline
}

.content_box .center_text {
    text-align: center
}

.toggle_content h2 {
    font-size: 18px;
    color: #30333a;
    cursor: pointer;
    text-align: left;
    padding-bottom: 10px;
    background: url(../images/bgr_toggle_h3.png) no-repeat 0 -136px;
    padding-left: 43px;
    line-height: 30px;
}

.toggle_content p {
    font-size: 16px;
    padding-bottom: 0;
}

#banner > img {
    width: 100%
}

.two_box_holder {
    max-width: 965px;
    margin: 0 auto;
}

.content_box_left {
    width: 30%;
    float: left;
    padding-top: 70px;
}

.content_box_right {
    width: 64%;
    float: right;
}

/*TODO: remove the following rule once h5 is converted to h2 in the cms*/
.content_box_right h5 {
    font-size: 19px;
    font-weight: 700;
    color: #30333a;
    padding-bottom: 20px;
}

.content_box_right h2 {
    font-size: 19px;
    font-weight: 700;
    color: #30333a;
    padding-bottom: 20px;
}

.content_box_right p {
    font-size: 16px;
    color: #505559;
    padding-bottom: 15px;
}

.content_box_right p strong {
    color: #30333a;
}

.content_box_bottom {
    margin-bottom: -50px;
    padding: 0
}

.register_content {
    max-width: 930px;
    margin: 0 auto;
    overflow: hidden;
}

.register_right {
    background: #f6f8f8;
    padding: 19px 20px 10px 20px;
}

.register_right ul {
    font-size: 14px;
}

.register_right ul {
    list-style: circle;
    padding-left: 30px;
}

/*TODO: remove the two following rules once it has been replaced by h3*/
.register_right h5,
.register_right p {
    color: #505559;
    font-size: 15px;
    padding-bottom: 19px;
}

.register_right h5 {
    font-weight: 700;
    padding-bottom: 3px;
}

.register_right h3,
.register_right p {
    color: #505559;
    font-size: 15px;
    padding-bottom: 19px;
}

.register_right h3 {
    font-weight: 700;
    padding-bottom: 3px;
}

.register_widget {
    position: relative;
    padding-left: 62px;
}

.register_widget.dot-bg {
    background: url(../images/bgr_dot_2.png) repeat-y 23px 100%;
}

.register_widget span {
    font-size: 27px;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: #50b848;
    line-height: 47px;
    text-align: center;
    display: block;
    position: absolute;
    color: #fff;
    left: 0;
    top: 0;
}

.register_widget h2 {
    font-size: 21px;
    padding-top: 0;
    padding-bottom: 19px;
}

/*TODO: remove the following rule once replaced by h2 in the cms*/
.register_widget h3 {
    font-size: 21px;
    color: #30333a;
    padding-top: 0;
    padding-bottom: 19px;
}

.register_widget p {
    font-size: 16px;
    color: #30333a;
    padding-bottom: 17px;
}

.register_widget strong {
    font-size: 16px;
    color: #7084aa;
    display: inline-block;
    padding-left: 22px;
    font-weight: 400;
    background: url(../images/bgr_arrow2.png) no-repeat left 5px;
}

.register_widget strong a {
    color: #7084aa
}

.search_domain {
    border: 1px solid #bababa;
    height: 37px;
    padding-left: 28px;
    margin-bottom: 7px;
    position: relative;
    margin-right: 112px;
    background: url(../images/bgr_search2.png) no-repeat 6px center;
}

.search_domain input[type="submit"] {
    width: 106px;
    height: 37px;
    border: none;
    background: #20325f;
    border-radius: 0;
    line-height: 37px;
    padding: 0;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: -112px;
    top: -1px;
}

.search_domain input[type="submit"]:hover {
    background: #5b89e1;
}

.search_domain input[type="text"] {
    width: 100%;
    border: none;
    background: none;
    color: #9f9b9b;
    font-size: 16px;
    height: 35px;
}

.register_content_full {
    max-width: 1200px;
    margin: 0 auto;
    padding: 33px 0 36px 0;
}

.register_content_full .clicking_here {
    text-align: center;
    font-size: 17px;
    color: #30333a;
}

.register_content_full .clicking_here a {
    display: inline-block;
    padding: 0 30px;
    height: 43px;
    line-height: 43px;
    background: #20325f;
    color: #fff;
    border-radius: 0;
}

.register_content_full .clicking_here a:hover {
    color: #fff;
    background: #5b89e1;
}

.register_content_box {
    padding-top: 40px
}

.publication-block-item p {
    font-size: 14px;
}

.well-publication {
    min-height: 20px;
    padding: -4px;
    margin-bottom: 20px;
    border-bottom: 1px dotted #C1BEBE;
    margin-top: 5px;
}

.well-publication:nth-child(1) {
    margin-top: 0;
}

.well-publication > .row {
    margin-bottom: 10px;
}

.overlay {
    background: transparent;
    position: relative;
    width: 100%;
    height: 480px; /* your iframe height */
    top: 480px; /* your iframe height */
    margin-top: -480px; /* your iframe height */
}

.homepage-status-wrapper {
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
}

ul.column-list, ol.column-list {
    margin-bottom: 20px;
    overflow: hidden;
    padding-left: 0;
}

ul.column-list li, ol.column-list li {
    line-height: 2em;
    border-bottom: 1px dotted #ccc;
    float: left;
    display: inline;
}

#double li {
    width: 50%;
}

#triple li {
    width: 33.333%;
}

#quad li {
    width: 25%;
}

#six li {
    width: 16.666%;
}

.publication-image {
    border: 1px solid #D6D2D2;
}

.register_content_full strong {
    font-size: 17px;
    color: #30333a;
    text-align: center;
    display: block;
}

.register_content_full strong a {
    text-align: center;
    text-decoration: underline;
    color: #30333a;
}

.domain_info {
    max-width: 920px;
    margin: 0 auto;
    padding-bottom: 47px;
}

.domain_info p {
    font-size: 20px;
    text-align: center;
    color: #505559;
}

.domain_info p a {
    color: #505559;
    text-decoration: underline
}

.register_content_full .feature_column {
    width: 20%;
    padding-bottom: 10px;
}

.other_news .feature_column {
    padding-bottom: 0;
}

.content_search input[type="submit"]:hover,
.navbar-default .navbar-nav > li > a:hover,
.get_started:hover,
.register_content_full .clicking_here a:hover,
.about_column a:hover,
.team_box:hover,
.search_domain input[type="submit"]:hover {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.about_top_content {
    max-width: 1064px;
    margin: 0 auto;
    padding: 50px 10px 0px 10px;
}

.count_area p {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 25px;
}

.more_domain {
    text-align: center;
    padding: 25px 0;
}

.more_domain a {
    font-size: 16px;
    color: #7285ad;
    text-decoration: underline;
}

.clear_mb {
    display: none;
    clear: both;
}

.service_row {
    overflow: hidden;
    padding: 25px 30px 50px 30px;
    color: #505559;
}

.service_left {
    width: 48.21%;
    float: left;
    padding-bottom: 25px;
    text-align: center;
}

.service_left h3 {
    font-size: 26px;
    padding-bottom: 25px;
    font-weight: normal;
}

.service_left p {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 20px;
}

.service_rgt {
    width: 39.45%;
    float: right;
    padding-bottom: 25px;
}

.service_img {
    width: 36%;
    float: left;
    overflow: hidden;
    padding-bottom: 30px;
}

.service_img img {
    float: right;
}

.padding_top {
    padding-top: 120px;
}

.about_row {
    overflow: hidden;
}

div.about_column {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
}

div.about_column .inner {
    min-height: 350px;
    background: #fff;
}

.about_column a {
    background: #fff;
}

.about_column a:hover {
    background: #e6e8ff;
}

.column_inside {
    margin: 0 auto;
    text-align: center;
    color: #505559;
}

img.about_icon {
    padding-top: 20px;
    margin: 0 auto;
}

h3.column_inside {
    font-weight: normal;
    font-size: 26px;
    padding-bottom: 25px;
    padding-top: 20px;
}

p.column_inside {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 25px;
    padding-right: 20px;
    padding-left: 20px;
}

.contact_main {
    text-align: center;
    color: #505559;
}

.contact_main p {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 20px;
}

.contact_main h2 {
    font-size: 30px;
    font-weight: normal;
    padding-bottom: 25px;
}

.contact_main h3 {
    font-size: 20px;
    padding-bottom: 20px;
    color: #30333a;
}

.list_item {
    padding-left: 17.66%;
}

.list_item li {
    min-height: 37px;
    padding: 5px 0 0 58px;
    position: relative;
    font-size: 16px;
    margin-bottom: 11px;
}

.list_item li span {
    display: block;
    background: #4bc26c;
    width: 37px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
}

.become_ragi {
    max-width: 960px;
    margin: 0 auto;
    color: #505559;
    padding: 50px 0;
}

.become_ragi p {
    font-size: 16px;
    line-height: 20px;
}

p.regi_first_para {
    text-align: center;
    padding-bottom: 40px;
    font-size: 24px;
}

.document_repo_first_para {
    font-size: 28px;
    text-align: center;
    padding-bottom: 40px;
    max-width: 840px;
    margin: 0 auto;
    line-height: 36px;
}

.become_ragi .toggle_content_area {
    padding-left: 25px;
}

.already_regi {
    padding-left: 25px;
    padding-top: 40px;
}

.readmore_btn {
    text-align: center;
    padding-bottom: 40px;
}

/*TODO: remove this rule once the h5 has been converted to h3*/
.already_regi h5 {
    font-weight: 600;
    font-size: 16px;
}

.already_regi h2 {
    font-weight: 600;
    font-size: 16px;
}


.all_offices {
    padding-bottom: 40px;
    overflow: hidden;
    max-width: 964px;
    margin: 0 auto;
    text-align: center;
}

.office_column {
    width: 49%;
    padding: 0 7px 15px 7px;
    color: #30333a;
    display: inline-table;
    text-align: left;
}

.office_box {
    background: #f7f7f7;
    position: relative;
    min-height: 150px;
    font-size: 16px;
}

.all_offices h3 {
    padding-bottom: 3px;
}

.office_box .btn-eurid.btn-primary {
    position: absolute;
    bottom: 15px;
    right: 25px;
    border: 0;
    padding: 0 15px;
}

@media screen and (max-width: 1290px) {
    .slider_info_area {
        padding: 0 80px;
    }

    .loading #slider .flex-viewport {
        height: 100%;
    }

    .loading #slider.flexslider {
        height: 100%;
    }

}

@media screen and (max-width: 1113px) {

    .column_item h3 {
        font-size: 22px;
    }

    .column_item p {
        font-size: 16px;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 1075px) {
    .content_left {
        width: 65%;
    }

    .feature_info h2 {
        font-size: 20px;
    }

    .plain_text_content .other_news {
        padding-left: 0;
    }

    .count_area {
        padding-bottom: 40px;
    }

}

@media screen and (max-width: 980px) {
    .slider_info h1 {
        font-size: 30px;
    }

    .slider_info {
        padding: 59px 20px 20px 20px;
    }

    .banner_info_btm {
        bottom: 10px;
    }

    .banner_info_btm div.banner_title {
        font-size: 30px;
    }

    .banner_info_btm p {
        font-size: 16px;
    }

}


@media screen and (max-width: 920px) {

    .feature_column {
        width: 50%;
    }

    .content_left {
        width: 60%;
    }

    .faq_column h6 {
        font-size: 22px;
    }

    .content_box p,
    .content_box ul li {
        font-size: 16px;
    }

    #banner::before {
        height: 120px;
        background-size: 100% 100%;
    }


}

@media screen and (max-width: 880px) {


    .feature_info h2 {
        font-size: 18px;
    }

    .faq_column_holder {
        margin-left: -15px;
    }

    .faq_column {
        padding-left: 15px;
    }

    .toggle_content p {
        font-size: 15px;
    }

    .client_logo_box {
        margin-left: 0;
    }
}

@media screen and (max-width: 854px) {

    .landing_home h1, .landing_home label[for="id_domain_name"] {
        font-size: 40px;
        padding-bottom: 30px;
    }

    .column_item h3 {
        font-size: 20px;
    }

    .column_item p {
        font-size: 15px;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 790px) {

    .content_left {
        width: 100%;
        float: none;
        padding-bottom: 30px;
    }

    #sidebar {
        float: none;
    }
}

/* BOOTSTRAP3 XS */
@media screen and (max-width: 767px) {

    .register_widget p {
        text-align: center;
    }

    .office_box {
        min-height: inherit;
        max-width: 450px;
        margin: 0 auto;
    }

    .office_box img {
        margin: 0 auto;
    }

    .register_widget.dot-bg {
        background: none;
    }

    .about_top_content {
        padding: 50px 40px 0px 40px;
    }

    h1.plain_text_title {
        font-size: 25px !important;
        font-weight: 300 !important;
    }

    .plain_text_title {
        font-weight: 300;
        padding: 0 0 25px;
        margin: 0 0 30px;
        font-size: 40px;
        color: #30333a;
        text-align: center;
        background: url(../images/bgr_title.png) no-repeat center bottom;
    }

    .landing_home h1, .landing_home label[for="id_domain_name"] {
        font-size: 30px;
    }

    .landing_home {
        padding: 20px 10px 20px 10px;
        text-align: center;
    }

    .home_search .form-control {
        height: 40px
    }

    .home_search input[type="submit"] {
        display: block
    }

    .banner_content {
        padding-bottom: 0;
    }

    .home_content_area {
        margin-top: 0;
        padding-top: 20px;
    }

    .home_three_column {
        display: none
    }

    .landing_page {
        background-attachment: fixed;
    }

    .landing_page #wrapper {
        margin-bottom: -70px;
    }

    .landing_page .sticky_footer, .landing_page #wrapper:after {
        height: 70px;
    }

    .flex-direction-nav .flex-prev {
        left: 10px;
    }

    .flex-direction-nav .flex-next {
        right: 10px;
    }

    .slider_info_area {
        padding: 0 60px;
    }

    #banner > img,
    #slider .slides li img {
        width: 100%
    }

    .banner_info_btm p {
        display: none
    }

    .banner_info_btm {
        bottom: 20px
    }

    .other_news ul li {
        font-size: 16px;
    }

    .plain_text_content h3 {
        font-size: 25px;
    }

    .toggle_content h2 {
        font-size: 18px;
    }

    .content_box_left {
        width: 100%;
        float: none;
        padding-bottom: 25px;
        padding-top: 0;
    }

    .content_box_left img {
        margin: 0 auto
    }

    .content_box_right {
        width: 100%;
        float: none;
    }

    .content_box_right p {
        font-size: 16px
    }

    .content_box_bottom {
        margin: 0 auto
    }

    .register_right {
        margin-bottom: 25px;
        text-align: center;
    }

    .register_right ul {
        list-style: none;
        padding-left: 0;
    }

    .register_widget {
        padding-left: 0;
        background: none;
    }

    .register_widget h3 {
        text-align: center
    }

    .register_widget span {
        position: relative;
        left: inherit;
        top: inherit;
        margin: 0 auto 5px;
    }

    #nav_benefit .list_item {
        padding-left: 25px !important;
    }

    #triple li {
        width: 50%;
    }

}

@media screen and (max-width: 640px) {

    .office_column {
        display: block;
        width: 100%;
        padding: 0 0 15px;
    }

    .flex-direction-nav {
        display: none
    }

    .slider_info_area {
        bottom: 10px;
        padding: 0 10px
    }

    .slider_info h1 {
        font-size: 25px;
    }

    .report {
        top: 15px;
    }

    .feature_box {
        margin-left: 0;
    }

    .feature_column {
        width: 100%;
        padding: 0 0 20px 0;
        float: none;
    }

    .register_content_full .feature_column {
        width: 100%;
        padding-bottom: 20px;
    }

    .feature_info {
        min-height: inherit;
        padding-bottom: 20px
    }

    .entry h4,
    .main_container h4 {
        font-size: 22px;
        padding-bottom: 30px;
    }

    .content_search {
        padding: 0 0 25px 0;
    }

    .content_search input[type="submit"] {
        max-width: 177px;
        margin: 0 auto;
        display: block;
        position: relative;
        left: inherit;
        top: inherit;
    }

    .faq_column_holder {
        margin-left: 0;
    }

    .faq_column {
        width: 100%;
        float: none;
        padding-left: 0;
        padding-bottom: 25px;
    }

    #pop_search {
        padding-right: 0;
    }

    #pop_search form {
        padding-right: 0;
    }

    #pop_search label {
        font-size: 30px;
        width: 100%;
    }

    #pop_search input {
        height: 40px;
        padding: 10px;
        font-size: 18px;
        margin-bottom: 10px;
        width: calc(100% - 135px);
    }

    #pop_search input[type="submit"] {
        position: absolute;
        right: 0;
        bottom: inherit;
        width: 130px;
        max-width: 320px;
        line-height: 40px;
        padding: 0;
        height: 40px;
        font-size: 20px;
    }

    .service_row {
        padding-left: 0;
        padding-right: 0;
    }

    .service_left,
    .service_rgt,
    .service_img {
        float: none;
        width: 100%;
    }

    .service_row {
        padding-bottom: 0;
    }

    .padding_top {
        padding-top: 0;
    }

    .service_rgt img {
        margin: 0 auto;
    }

    .service_img img {
        float: none;
        margin: 0 auto;
    }

    .list_item,
    .become_ragi .toggle_content_area,
    .already_regi {
        padding-left: 0;
    }
}

@media screen and (max-width: 500px) {

    .slider_info h1 {
        font-size: 20px;
    }

    .banner_logo {
        float: none;
        padding-bottom: 10px;
    }

    .clear_mb {
        display: block;
    }

    .about_column {
        width: 100%;
    }

    #about_row {
        margin-right: 0;
    }
}

@media screen and (max-width: 450px) {
    #triple li {
        width: 100%;
    }
}


/*----------------------*/
/*   time line structure   */
/*----------------------*/
#timeline {
    max-width: 930px;
    margin: 0 auto;
    padding: 0 10px;
}

#timeline_holder {
    background: url(../images/bdr_timeline.jpg) repeat-y 133px 100%;
    padding-top: 29px;
    min-height: 700px;
}

.year_timeline {
    position: relative;
    padding-left: 177px;
    min-height: 85px;
}

.timeline_left {
    width: 116px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: right;
    padding-right: 3px;
}

.year_holder {
    display: inline-block;
    font-size: 20px;
    color: #fff;
    padding: 0 10px;
    line-height: 38px;
    background: #20325f;
    position: relative;
    font-weight: 700;
    margin-top: 28px;
}

.year_holder:after {
    width: 24px;
    height: 24px;
    background: #d8d8d8;
    content: '';
    position: absolute;
    right: -32.5px;
    top: 6px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

}

.timeline_info {
    background: #fafafa;
    padding: 18px 20px 20px 20px;
    position: relative;
    font-weight: 300;
    color: #030303;
    font-size: 18px;
    line-height: 24px;
    min-height: 90px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    max-width: 600px;
    margin-bottom: 20px;

}

.timeline_info:after {
    right: 100%;
    top: 34px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    border-right: 22px solid #fafafa;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
}

.month_holder {
    font-size: 16px;
    font-weight: 300;
    color: #030303;
    line-height: 38px;
    text-align: right;
    display: inline-block;
    margin-top: 24px;
}

.month_holder:after {
    width: 14px;
    height: 14px;
    background: #d8d8d8;
    content: '';
    position: absolute;
    right: -24px;
    top: 38px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

}

.big-caret {
    border-top: 6px dashed;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}

ul.other-news {
    list-style: none;
    padding-left: 0;
}

@media screen and (max-width: 767px) {
    ul .article-date {
        width: 100%
    }
}

.justify-content-center {
    justify-content: center !important;
}

.max-width-lg {
    max-width: 1200px;
    margin: auto
}

#welcome_to_eurid .content_box {
    padding: 0;
}

#welcome_to_eurid .content_box p {
    font-size: 16px;
    font-weight: 400;
}

#welcome_to_eurid .countup_plugin {
    margin: 10px 0 50px 0;
}

#welcome_to_eurid .tot_reg .countup_number {
    font-size: 55px;
    font-weight: bold;
    color: #1f325f;
}

#welcome_to_eurid .new_reg .countup_number {
    font-size: 55px;
    font-weight: bold;
    color: #ce1515;
}

#welcome_to_eurid .small_reg .countup_number {
    margin-top: 20px;
    font-size: 35px;
    font-weight: bold;
    color: #4bc26c;
}

#welcome_to_eurid .countup_legend {
    font-variant: small-caps;
    font-weight: bold;
    font-size: 15px;
}


#welcome_to_eurid .plain_text_content {
    padding: 0px 10px 50px 10px;
}

#corporate_governance .plain_text_content {
    padding: 0px 10px;
}

#corporate_governance {
    line-height: 1.6em !important;
}

#corporate_governance .plain_text_content > p {
    font-size: 16px;
}

#corporate_governance .center_content a {
    text-decoration: underline;
}

#corporate_governance #slider {
    background-position: 0 28%;
}

#corporate_governance .banner_info_btm {
    margin: 14% auto 0;
}

#corporate_governance h5.member_title:hover {
    text-decoration: underline;
}

#corporate_governance .collapse_plugin > a {
    cursor: pointer;
    color: #30333a;
    display: block;
    font-size: 30px;
    line-height: 33px;
    padding: 50px 0 50px 0;
    text-align: center;
    text-decoration: none;
}


@media screen and (min-width: 1200px) {
    #corporate_governance .membership_member_list {
        width: 120%;
        left: -10%;
        position: relative;
    }
}


#corporate_governance .membership_member_list .team_box {
    height: 225px;
    padding-top: 8px;
    background: transparent;
    border: 0;
    margin-top: 12px;
}

#corporate_governance .membership_member_list .member_title {
    padding-top: 15px;
}

#corporate_governance .membership_member_list img {
    max-width: 65%;
}

#corporate_governance #strategic_committee .team_box {
    background: #4bc26c;
    color: #fff;
    height: 95px;
}

@media screen and (min-width: 1200px) {
    #corporate_governance .strategic_committee_people_list {
        width: 135%;
        left: -17.5%;
        position: relative;
    }
}


#corporate_governance #strategic_committee .board_member .team_box {
    background: #20325f;
}

#corporate_governance #strategic_committee .team_box h5 {
    color: #fff;
    padding-top: 30px;
    line-height: 21px;
}

#corporate_governance #strategic_committee .board_member .team_box h5 {
    color: #fff;
    padding-top: 10px;
    line-height: 21px;
}

#corporate_governance .team_box p {
    color: #fff;
    position: absolute;
    display: inline-block;
    width: 100%;
    left: 0;
    font-size: 13px;
}

#corporate_governance #strategic_committee .team_box p.member_info.member_group {
    bottom: 30px;
}

#corporate_governance #strategic_committee .team_box p.member_info.member_group {
    bottom: 0px;
}

@media screen and (min-width: 1200px) {
    #corporate_governance .team_management_people_list {
        position: relative;
        left: -17.5%;
        width: 135%;
    }
}

#corporate_governance #team_management .team_box {
    background: #20325f;
    height: 100px;
}

#corporate_governance #team_management .team_box p {
    bottom: 12px;
    color: #fff;
}

#corporate_governance #team_management .team_box h5 {
    line-height: 40px;
    color: #fff;
}

#corporate_governance .team_management_people_list a {
    text-decoration: none;
}

#corporate_governance .strategic_committee_people_list a {
    text-decoration: none;
}

#corporate_governance .membership_member_list a {
    text-decoration: none;
}

#about_us_and_careers .plain_text_content {
    padding: 0px 10px 50px 10px;
}

#about_us_and_careers .award_pic {
    height: 240px;
    max-width: 320px;
    margin: auto;
}

#about_us_and_careers {
    line-height: 1.6em !important;
}

@media (min-width: 1200px) {
    #CybotCookiebotDialogBody {
        max-width: 992px !important;
    }
}

#eu_live_talks .plain_text_content {
    padding: 0px 10px;
}

#eu_live_talks {
    line-height: 1.6em !important;
}

#eu_live_talks .plain_text_content > p {
    font-size: 16px;
}

#eu_live_talks .center_content a {
    text-decoration: underline;
}

#eu_live_talks #slider {
    background-position: 0 0;
}

#eu_live_talks .collapse_plugin > a {
    cursor: pointer;
    color: #30333a;
    display: block;
    font-size: 30px;
    line-height: 33px;
    padding: 50px 0 50px 0;
    text-align: center;
}

/*not easy to hover*/
@media (hover: none) {
    .desktop_view {
        display: none;
    }
}

/* easy to hover */
@media (hover: hover){
    .mobile_tablet_view {
        display: none;
    }
    .mobile_view {
        display: none;
    }
    .tablet_view {
        display: none;
    }
}

select[multiple].form-control {
    height: 34px;
}

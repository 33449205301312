/* TABLES */

.table-data tr td:first-child {
    font-weight: bold;
}

table.borderless td, table.borderless th, table.borderless tr, table.borderless tbody{
    border: none !important;
}

/* BUTTONS */

.btn-eurid {
    border: 0;
    border-radius: 0;
}

.btn-eurid:hover {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.btn-eurid.btn-eurid-lg{
    display: inline-block;
    padding: 0 38px;
    height: 42px;
    font-size: 18px;
    text-align: center;
    line-height: 42px;
}

/* primary */
.btn-eurid.btn-primary {
    background: #5b89e1;
    color: #fff;
}

.btn-eurid.btn-primary:hover {
    color: #fff;
    background: #20325f;
}

.btn-eurid.btn-primary:focus,
.btn-eurid.btn-primary.focus {
    color: #fff;
    background-color: #20325f;
    border-color: #122b40;
}

.btn-eurid.btn-primary:active,
.btn-eurid.btn-primary.active,
.open > .dropdown-toggle.btn-eurid.btn-primary {
    color: #fff;
    background-color: #20325f;
    border-color: #204d74;
}

.btn-eurid.btn-primary:active:hover,
.btn-eurid.btn-primary.active:hover,
.open > .dropdown-toggle.btn-eurid.btn-primary:hover,
.btn-eurid.btn-primary:active:focus,
.btn-eurid.btn-primary.active:focus,
.open > .dropdown-toggle.btn-eurid.btn-primary:focus,
.btn-eurid.btn-primary:active.focus,
.btn-eurid.btn-primary.active.focus,
.open > .dropdown-toggle.btn-eurid.btn-primary.focus {
    color: #fff;
    background-color: #20325f;
    border-color: #122b40;
}

.btn-eurid.btn-primary:active,
.btn-eurid.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-eurid.btn-primary.disabled:hover,
.btn-eurid.btn-primary[disabled]:hover,
fieldset[disabled] .btn-eurid.btn-primary:hover,
.btn-eurid.btn-primary.disabled:focus,
.btn-eurid.btn-primary[disabled]:focus,
fieldset[disabled] .btn-eurid.btn-eurid.btn-primary:focus,
.btn-eurid.btn-primary.disabled.focus,
.btn-eurid.btn-primary[disabled].focus,
fieldset[disabled] .btn-eurid.btn-primary.focus {
    background-color: #5b89e1;
    border-color: #2e6da4;
}

.btn-eurid.btn-primary .badge {
    color: #5b89e1;
    background-color: #fff;
}

/* dark blue 5b89e1 */
.btn-eurid.btn-dark-blue {
    background: #20325f;
    color: #fff;
}

.btn-eurid.btn-dark-blue:hover {
    color: #fff;
    background: #5b89e1;
}

.btn-eurid.btn-dark-blue:focus,
.btn-eurid.btn-dark-blue.focus {
    color: #fff;
    background-color: #5b89e1;
    /*border-color: #122b40;*/
}

.btn-eurid.btn-dark-blue:active,
.btn-eurid.btn-dark-blue.active,
.open > .dropdown-toggle.btn-eurid.btn-dark-blue {
    color: #fff;
    background-color: #5b89e1;
    /*border-color: #204d74;*/
}

.btn-eurid.btn-dark-blue:active:hover,
.btn-eurid.btn-dark-blue.active:hover,
.open > .dropdown-toggle.btn-eurid.btn-dark-blue:hover,
.btn-eurid.btn-dark-blue:active:focus,
.btn-eurid.btn-dark-blue.active:focus,
.open > .dropdown-toggle.btn-eurid.btn-dark-blue:focus,
.btn-eurid.btn-dark-blue:active.focus,
.btn-eurid.btn-dark-blue.active.focus,
.open > .dropdown-toggle.btn-eurid.btn-dark-blue.focus {
    color: #fff;
    background-color: #5b89e1;
    /*border-color: #122b40;*/
}

.btn-eurid.btn-dark-blue:active,
.btn-eurid.btn-dark-blue.active,
.open > .dropdown-toggle.btn-dark-blue {
    background-image: none;
}

.btn-eurid.btn-dark-blue.disabled:hover,
.btn-eurid.btn-dark-blue[disabled]:hover,
fieldset[disabled] .btn-eurid.btn-dark-blue:hover,
.btn-eurid.btn-dark-blue.disabled:focus,
.btn-eurid.btn-dark-blue[disabled]:focus,
fieldset[disabled] .btn-eurid.btn-eurid.btn-dark-blue:focus,
.btn-eurid.btn-dark-blue.disabled.focus,
.btn-eurid.btn-dark-blue[disabled].focus,
fieldset[disabled] .btn-eurid.btn-dark-blue.focus {
    background-color: #5b89e1;
    border-color: #2e6da4;
}

.btn-eurid.btn-dark-blue .badge {
    color: #20325f;
    background-color: #fff;
}

/* danger */
.btn-eurid.btn-danger{
    background: #d9534f;
    color: #fff;
}

.btn-eurid.btn-danger:hover {
    color: #fff;
    background: #c9302c;
}

/* success */
.btn-eurid.btn-success {
    background-color: #50b848;
}

.btn-eurid.btn-success:focus,
.btn-eurid.btn-success.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625;
}

.btn-eurid.btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
}

.btn-eurid.btn-success:active,
.btn-eurid.btn-success.active,
.open > .dropdown-toggle.btn-eurid.btn-success {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
}

.btn-eurid.btn-success:active:hover,
.btn-eurid.btn-success.active:hover,
.open > .dropdown-toggle.btn-eurid.btn-success:hover,
.btn-eurid.btn-success:active:focus,
.btn-eurid.btn-success.active:focus,
.open > .dropdown-toggle.btn-eurid.btn-success:focus,
.btn-eurid.btn-success:active.focus,
.btn-eurid.btn-success.active.focus,
.open > .dropdown-toggle.btn-eurid.btn-success.focus {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
}

.btn-eurid.btn-success:active,
.btn-eurid.btn-success.active,
.open > .dropdown-toggle.btn-eurid.btn-success {
    background-image: none;
}

.btn-eurid.btn-success.disabled:hover,
.btn-eurid.btn-success[disabled]:hover,
fieldset[disabled] .btn-eurid.btn-success:hover,
.btn-eurid.btn-success.disabled:focus,
.btn-eurid.btn-success[disabled]:focus,
fieldset[disabled] .btn-eurid.btn-success:focus,
.btn-eurid.btn-success.disabled.focus,
.btn-eurid.btn-success[disabled].focus,
fieldset[disabled] .btn-eurid.btn-success.focus {
    background-color: #50b848;
    border-color: #4cae4c;
}

.btn-eurid.btn-success .badge {
    color: #50b848;
    background-color: #fff;
}

/*circle*/
.btn-circle, .btn-eurid.btn-circle {
  width: 30px !important;
  height: 30px !important;
  text-align: center;
  padding: 6px 0 !important;
  font-size: 12px !important;
  line-height: 1.428571429 !important;
  border-radius: 15px !important;
}

/* INPUTS AND PARSLEY */

input.parsley-error, textarea.parsley-error, .has-error input , .has-error div#captcha{
    margin-bottom: 24px;
}

input.parsley-error + .help-block, textarea.parsley-error + .help-block,
input.parsley-error + .errors + .help-block, textarea.parsley-error + .errors + .help-block,
.has-error .help-block{
    margin-top: 10px;
}

.parsley-errors-list:not(.filled){
    display: none;
}

input[type="checkbox"]{
    -webkit-appearance: checkbox;
    -webkit-border-radius: 0;
}

.big-checkbox input[type="checkbox"]{width: 30px; height: 30px;}

.toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20px; }

.toggle.ios .toggle-handle { border-radius: 20px; }

.arrow_box_success {
    position: relative;
    padding: 15px;
    text-align: left;
    background: #dff0d8;
}

.arrow_box_danger {
    position: relative;
    padding: 15px;
    text-align: left;
    background: #f2dede;
}

.arrow_box_danger:after {
    bottom: 100%;
    left: 12%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 15px solid transparent;
    border-bottom-color: #f2dede;
}

.arrow_box_success:after {
    bottom: 100%;
    left: 12%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 15px solid transparent;
    border-bottom-color: #dff0d8;

}

.arrow_box_danger_small {
    padding-top: 10px;
    padding-bottom: 10px;
}

/* TYPOGRAPHY */

label {
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 300;
}

.text-white {
    color: white;
}

.text-small {
    font-size: .8em !important;
}

.text-italic {
    font-style: italic;
}

.fade-out-text {
    margin-bottom: 5px;
    padding-bottom: 5px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    content: "";
    position: relative;
}

.fade-out-text:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 55px, #f8f8f8);
}

.fade-out-text-white:before{
    background: linear-gradient(transparent 55px, white);
}


@media screen and (max-width: 767px){
    .text-xs-center {
        text-align: center;
    }

    .text-xs-left {
        text-align: left;
    }

    .text-xs-right {
        text-align: right;
    }
}

/* BADGES */

.badge-primary{background-color:#438ce2}
.badge-info{background-color:#00bcd4}
.badge-success{background-color:#5cb85c}
.badge-warning{background-color:#ffa726}
.badge-danger{background-color:#ef5350}
.badge-mint{background-color:#26a69a}
.badge-purple{background-color:#ba68c8}
.badge-pink{background-color:#ec407a}
.badge-dark{background-color:#263238}
.badge-light-blue{background-color: #3792a7}

/* PADDING */

.pad-rgt {
    padding-right: 15px !important;
}
.pad-btm {
    padding-bottom: 15px !important;
}
.pad-top {
    padding-top: 15px !important;
}
.pad-lft {
    padding-left: 15px !important;
}
.pad-no {
    padding: 0 !important;
}

@media screen and (max-width: 767px){
    .pad-top-xs {
        padding-top: 15px !important;
    }
    .pad-btm-xs {
        padding-bottom: 15px !important;
    }
    .pad-rgt-xs {
        padding-right: 15px !important;
    }
    .pad-lft-xs {
        padding-left: 15px !important;
    }
    .pad-no-xs {
        padding: 0 !important;
    }
}

/* MARGIN */

.mar-rgt {
    margin-right: 15px !important;
}
.mar-btm {
    margin-bottom: 15px !important;
}
.mar-top {
    margin-top: 15px !important;
}
.mar-lft {
    margin-left: 15px !important;
}
.mar-no {
    margin: 0 !important;
}

@media screen and (max-width: 767px){
    .mar-btm-xs {
        margin-bottom: 15px !important;
    }
    .mar-top-xs {
        margin-top: 15px !important;
    }
    .mar-rgt-xs {
        margin-right: 15px !important;
    }
    .mar-lft-xs {
        margin-left: 15px !important;
    }
    .mar-no-xs {
        margin: 0 !important;
    }
}

/* FLOAT */

@media screen and (max-width: 767px){
    .pull-left-xs {
        float: left !important
    }

    .pull-none-xs {
        float: none !important
    }

    .pull-right-xs {
        float: right !important
    }
}

/* OTHERS */

.clearfix {
    clear: both;
    width: 100%;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
.row-eq-height > * {
    margin-top: 15px;
    margin-bottom: 15px;
}
.row-eq-height:before {
    display: none;
}

/* CHART PLUGIN */

.chart-plugin .second-row {
    background-color: #20325f;
    border-radius: 30px;
    margin-top: 45px;
}

.chart-plugin .second-row img {
    position: relative;
    top: -35px;
    margin-left: auto;
    margin-right: auto;
}

.chart-plugin *[id^=count_total]{
    border-bottom: 1px solid grey;
    font-weight: 300;
    margin-top: 8px;
}

.chart-plugin *[id^=renewal-text]{
    display: none;
}

.chart-plugin span.fa-stack {
    top: -54px;
}

.chart-plugin i.fa-certificate {
    color: #5b89e1;
}

.chart-plugin #col-right {
    padding-right: 0;
}

.chart-plugin #col-left {
    padding-left: 0;
}

.chart-plugin strong.fa-stack-1x {
    font-size: 25px;
}

.chart-plugin *[id^=count-new]{
    position: relative;
    top: -30px;
}

.chart-plugin *[id^=renewal-rate-]{
    padding-top: 8px;
}

.chart-plugin *[id^=new-registrations-text]{
    position: relative;
    top: -30px;
    display: none;
}

@media screen and (max-width: 620px){
    .chart-plugin h2{
        font-size: 25px;
    }

    .chart-plugin *[id^=renewal-rate-]{
        padding-top: 0;
    }

    .chart-plugin *[id^=renewal-text-], .chart-plugin *[id^=new-registrations-text]{
        font-size: 14px;
    }

    .chart-plugin *[id^=new-registrations-text], .chart-plugin *[id^=count-new]{
        top: -39px;
    }
}


@media screen and (max-width: 450px){
    .chart-plugin h2{
        font-size: 20px;
    }

    .chart-plugin *[id^=renewal-rate-]{
        padding-top: 0;
    }

    .chart-plugin *[id^=renewal-text-], .chart-plugin *[id^=new-registrations-text]{
        font-size: 12px;
    }

    .chart-plugin *[id^=new-registrations-text], .chart-plugin *[id^=count-new]{
        top: -39px;
    }
}

/* STORIES - TESTIMONIALS */

img.story-img {
    max-height: 127px;
}

#nav_disput img.story-img {
    max-height: 205px;
}

h6.story {
    padding-top: 6px;
    padding-bottom: 6px;
}

i.story-play {

    color: #20325f;
    border-radius: 50%;
    font-size: 2.5em;
}

i.story-play-circle {
    font-size: 2.3em;
}

span.story-play {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

i.story-play:hover, i.story-play:active{
    transition: background-color 0.5s ease;
    color: #5b89e1;
}

@media screen and (max-width: 767px){
    img.story-img, #nav_disput img.story-img {
        max-height: inherit;
    }

    i.story-play {
        font-size: 4.5em;
    }

    i.story-play-circle {
        top: 11px;
        font-size: 3.5em;
    }

    span.story-play {
        top: 40%;
        left: 47%;
    }
}

@media screen and (max-width: 500px){
    i.story-play {
        font-size: 3.5em;
    }
    i.story-play-circle {
        font-size: 2.5em;
        top: 3px;
        left: 4px
    }
}

/* CAROUSELS */

.carousel-control.left, .carousel-control.right {
    background-image: none !important;
    outline: 0;
 }
.carousel-control.left span, .carousel-control.right span {
    background-color: #20325f;
}

.carousel-control .btn, .carousel-control.btn{
    background-color: #20325f;
    color: white;
}

.carousel-control {
    opacity: 1;
}

.carousel-control:hover,
.carousel-control:active {
    color: white;
}

.carousel-control .btn:hover,
.carousel-control.btn:hover,
.carousel-control .btn:active,
.carousel-control.btn:active{
    background-color: #5b89e1;
    color: white;
}

ol.carousel-indicators {
    position: absolute !important;
    bottom: -40px;
}
.carousel .carousel-control,
.carousel:hover .carousel-control {
  visibility: visible;
}

.carousel-indicators {
    background-color: white;
}

.carousel-indicators li {
    width: 15px !important;
    height: 15px !important;
    background-color: #20325f;
    border: 4px solid #20325f;
    margin: 0;

}

.carousel-control .glyphicon {
    position: absolute;
    bottom: 0;
}

@media screen and (max-width: 520px) {

    ol.carousel-indicators {
        margin-left: 0;
        left: 0;
        width: 100%;
    }
}

/* PEOPLE LIST (MANAGEMENT AND MEMBERS IN ABOUT US) */

div.general_manager {
    padding-right: 15px;
    padding-left: 15px;
}

.team_management_members {
    margin: 0 auto !important;
    padding-top: 0 !important;
    padding-bottom: 50px !important;
}

.team_about {
    padding-bottom: 30px;
}

.person-clear-if-needed {
    display: none;
}

.team_about .person-clear-if-needed.person-3 {
    content: "";
    width: 100%;
    display: block;
}

.team_box {
    border: 1px solid #f6f4f5;
    background: #fff;
    border-radius: 2px;
    display: block;
}

.team_box:hover {
    background: #e6e8ff;
}

.team_img {
    margin: 0 auto;
    border: 5px solid #f5efef;
    padding: 3px;
    border-radius: 50%;
}

.author_info {
    padding-top: 12px;
    text-align: center;
}


h5.member_title {
    font-weight: normal;
    font-size: 16px;
    color: #494747;
    padding-top: 12px;
    display: block;
}

p.member_info {
    padding-bottom: 10px;
    display: block;
    font-size: 14px;
    color: #908f8f;
}

/*LATEST ARTICLES*/

.latest-articles .feature_column {
    display: flex;
    flex-direction: column;
}

.latest-articles .btn-eurid.btn-primary {
    margin-top: auto;
    max-width: 240px;
}

@media screen and (max-width: 767px){
    .latest-articles .feature_photo,
    .latest-articles .btn-eurid.btn-primary {
        margin-left: auto;
        margin-right: auto;
    }
}

/* REGISTRAR LIST */

.registrar-search-btn {
    margin-top: 20px;
    height: 36px !important;
    line-height: 36px !important;
}

.client_logo {
    float: left;
    border: 1px solid #D2CFCF;
    margin: 5px;
    width: 95%;
    height: 180px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    background-size: 150px;
    background: #fff no-repeat center;
}

.client_logo p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    word-break: break-all;
}

.client_logo img {
    border: 0;
}

#filter-registrar-list {
    display: none;
    background-color: #fff;
    padding: 20px;
}

.reglist-filters, .reglist-item {
    border-radius: 0;
    border: 0;
    background-color: rgba(153, 153, 153, 0.07);
}

.reglist-filters #div_id_active_promoter, #filter-registrar-list #div_id_active_promoter {
    margin-top: 25px;
}

@media screen and (max-width: 767px){
    .reglist-filters #div_id_active_promoter, #filter-registrar-list #div_id_active_promoter {
        margin-top: 10px;
    }
}

#registrar-list-results > div.row > div:nth-of-type(1) {
    padding-left: 0;
}


#registrar-list-results > div.row > div:nth-of-type(2){
    padding-right: 0;
}

.reg-item-lang.collapse.in{
    display: inline;
}

.reglist-filters #clear-filter {
    cursor: pointer;
    right: 15px;
    bottom: 0;
    position: absolute;
}

.client_logo_box {
    padding: 20px 0 30px 0;
    overflow: hidden;
    margin-left: 0;
}

.registrar-no-logo p, .no_logo p {
    word-break: break-all;
}

.registrar-no-logo, .no_logo {
    padding-top: 60px;
}

ul#help_text_ars {
    font-size: 12px;
    list-style: none;
}

ul#help_text_ars li {
    list-style: none;
    padding-left: 0;
    margin-bottom: 10px;
}

ul#help_text_ars li p.filter_name {
    font-size: 12px;
    color: #30333a;
}

ul#help_text_ars li p.filter_help {
    font-style: italic;
    color: #505559;
    font-size: 12px;
}

@media screen and (max-width: 767px) {
    .reglist-item, .reglist-item .search-result-title,
    .reglist-item div.col-md-6, .reglist-filters {
        text-align: center !important;
    }

    ul#help_text_ars ul {
        list-style: none;
    }
    .reglist-item ul {
        text-align: left;
        padding-left: 100px;
    }

    .reglist-item div.col-md-6 {
        padding-left: 0 !important;
    }
    .reglist-item .registrar-no-logo {
        display: none;
    }

    .reglist-item a img {
        display: inline;
    }


    #registrar-list-results > div.row > div {
        padding-right: 0;
        padding-left: 0;
    }
}

@media screen and (max-width: 640px){
    .client_logo_box {
        margin-left: 0;
    }

    .client_logo {
        width: 33.33%;
        padding: 0 8px 16px;
    }

    .js-random-registrar-list .client_logo {
        width: 95%;
    }
}

@media screen and (max-width: 500px){
    .reglist-filters #clear-filter {
        position: static;
    }

    .client_logo {
        width: 50%;
    }
}

/* NAV TABS PLUGIN */

@media screen and (max-width: 500px){
    ul.nav.nav-tabs.cms-plugin li a {
        font-size: 12px;
    }
}

/* CUSTOM HTML - ICON COLUMN */

/*TODO: delete the two following rules once their targets have been replaced by h2 in cms*/
.icon_column h4 {
    color: #494747;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 23px;
    padding-top: 12px;
}

.icon_column h4 a {
    color: #494747
}

.icon_column h2 {
    color: #20325f;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.icon_column h2 a {
    color: #494747
}

.icon_column p {
    font-size: 17px;
    color: #494747;
}

@media screen and (max-width: 767px) {
    .icon_column i {
        width: 100%;
        padding-bottom: 10px;
    }
}

/* TIMELINE PLUGIN (COPIED AND MODIFIED FROM NIFTY)*/
.timeline {
  position: static;
    display: flow-root;
  padding-bottom: 40px;
  color: #7a878e;
}
.timeline:before,
.timeline:after {
  background: rgb(191, 197, 201);
  background: -moz-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(191, 197, 201,1)), color-stop(100%,rgba(191, 197, 201,1)));
  background: -webkit-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
  background: -o-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
  background: -ms-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
  background: linear-gradient(to bottom, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
  bottom: 20px;
  content: "";
  display: block;
  position: absolute;
}
.timeline:before {
  left: 74px;
  top: 171px;
  width: 2px;
}

.timeline-header {
  border-radius: 3px;
  clear: both;
  margin-bottom: 50px;
  margin-top: 50px;
  position: relative;
}
.timeline-header .timeline-header-title {
  display: inline-block;
  text-align: center;
  padding: 7px 15px;
  min-width: 100px;
}
.timeline .timeline-header:first-child {
  margin-bottom: 30px;
  margin-top: 15px;
}
.timeline-stat {
  width: 100px;
  float: left;
  text-align: center;
  padding-bottom: 15px;
}
.timeline-entry {
  margin-bottom: 25px;
  margin-top: 5px;
  position: relative;
  clear: both;
}
.timeline-entry-inner {
  position: relative;
}
.timeline-time {
  word-break: break-word;
  display: inline-block;
  padding: 2px 3px;
  background-color: #fff;
  color: #494747;
    font-weight: 400;
  font-size: 1em;
  max-width: 120px;
}
.timeline-icon {
  border-radius: 50%;
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  margin: 5px auto 0;
  background-color: #fff;
}
.timeline-icon > i {
  line-height: 40px;
  vertical-align: 0.1em;
}
.timeline-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: top;
}
.timeline-icon:empty {
  height: 12px;
  width: 12px;
  margin-top: 20px;
  border: 2px solid #a3acb1;
}
.timeline-label {
  background-color: #1c4da1;
  border-radius: 10px;
  margin-left: 85px;
  padding: 10px;
  position: relative;
  min-height: 50px;
  color: white;
}
.timeline-label:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #1c4da1;
  margin: 15px 0 0 -9px;
}

.timeline-entry p {
    font-size: 16px;
}

@media (min-width: 768px) {
  .two-column.timeline {
    text-align: center;
  }
  .two-column.timeline:before {
    left: 49.95%;
  }
  .two-column.timeline:after {
    left: 50%;
    margin-left: -2px;
  }
  .two-column.timeline .timeline-entry {
    width: 50%;
    text-align: left;
  }
  .two-column.timeline .timeline-stat {
    margin-left: -50px;
  }
  .two-column.timeline .timeline-entry:nth-child(odd) {
    float: right;
  }
  .two-column.timeline .timeline-entry:nth-child(odd) .timeline-label {
    margin-left: 55px;
  }
  .two-column.timeline .timeline-header {
    text-align: center;
  }
  .two-column.timeline .timeline-entry:nth-child(even) {
    float: left;
  }
  .two-column.timeline .timeline-entry:nth-child(even) .timeline-stat {
    left: 100%;
    position: relative;
    margin-left: -50px;
  }
  .two-column.timeline .timeline-entry:nth-child(even) .timeline-label {
    left: -90px;
    margin-right: -35px;
  }
  .two-column.timeline .timeline-entry:nth-child(even) .timeline-label:after {
    left: auto;
    right: 0;
    border: 9px solid transparent;
    border-right-width: 0;
    border-left-color: #1c4da1;
    margin: 15px -8px 0 0;
  }
}

@media screen and (max-width: 767px){
  .timeline-label {
      margin-left: 100px;
  }
}

/* BACKGROUND DOTS */

body.bg-dots {
    background: no-repeat fixed;
}

body.bg-dots.left-top {
    background-position: left 20px top;
}
body.bg-dots.left-center {
    background-position: left 20px center;
}
body.bg-dots.left-bottom {
    background-position: left 20px bottom;
}
body.bg-dots.right-top {
    background-position: right 20px top;
}
body.bg-dots.right-center {
    background-position: right 20px center;
}
body.bg-dots.right-bottom {
    background-position: right 20px bottom;
}

body.bg-dots.center-top {
    background-position: center top;
}
body.bg-dots.center-center {
    background-position: center center;
}
body.bg-dots.center-bottom {
    background-position: center bottom;
}

body.bg-dots.grey{
    background-image: url("../images/background-dots/dots_lightgrey.png");
    background-size: 20%;
}
body.bg-dots.grey.vertical-dots{
    background-image: url("../images/background-dots/dots_lightgrey_flipped.png");
    background-size: 12%;
}

body.bg-dots.blue{
    background-image: url("../images/background-dots/dots_blue.png");
    background-size: 20%;
}
body.bg-dots.blue.vertical-dots{
    background-image: url("../images/background-dots/dots_blue_flipped.png");
    background-size: 12%;
}

body.bg-dots.green{
    background-image: url("../images/background-dots/dots_teal.png");
    background-size: 20%;
}
body.bg-dots.green.vertical-dots{
    background-image: url("../images/background-dots/dots_teal_flipped.png");
    background-size: 12%;
}

/* FOOTER DOTS */
div.dots-footer {
    width: 90%;
    margin: 0 auto;
    height: 4vw;
    background-repeat: no-repeat;
}

div.dots-footer.green{
    background-image: url("../images/background-dots/dots_teal.png");
    background-size: 20%;
}

div.dots-footer.right {
    background-position: right top;
}
div.dots-footer.left {
    background-position: left top;
}
div.dots-footer.center {
    background-position: center top;
}


div.dots-footer.blue{
    background-image: url("../images/background-dots/dots_blue.png");
    background-size: 20%;
}

div.dots-footer.grey{
    background-image: url("../images/background-dots/dots_lightgrey.png");
    background-size: 20%;
}

.news_post_box {
    position: relative;
}
.news_post_box:not(:empty):before {
    background: rgb(191, 197, 201);
    background: -moz-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(191, 197, 201,1)), color-stop(100%,rgba(191, 197, 201,1)));
    background: -webkit-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
    background: -o-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
    background: -ms-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
    background: linear-gradient(to bottom, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
    bottom: 20px;
    content: "";
    display: block;
    position: absolute;
}

.news_post_box:not(:empty):before {
  position: absolute;
  left: 25px;
  width: 2px;
  height: 100%;
}

@media screen and (max-width: 767px){
    .news_post_box:not(:empty):before {
      display: none;
    }

    .news_post {
        position: relative;
    }
    .news_post:not(:last-child):before {
        background: rgb(191, 197, 201);
        background: -moz-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(191, 197, 201,1)), color-stop(100%,rgba(191, 197, 201,1)));
        background: -webkit-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
        background: -o-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
        background: -ms-linear-gradient(top, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
        background: linear-gradient(to bottom, rgba(191, 197, 201,0) 0%, rgb(191, 197, 201) 8%, rgb(191, 197, 201) 92%, rgba(191, 197, 201,0) 100%);
        content: "";
        display: block;
        position: absolute;
        height: 34px;
        width: 2px;
        left :50%;
        bottom :-1px;
    }
}

.vertical_tabs_plugin .vertical_tabmenu {
    border-right: black solid 1px;
    white-space: nowrap;
    padding: 0 15px 0 20px;
}

@media screen and (min-width: 768px) {
    .vertical_tabs_plugin {
        display: flex;
    }
}

.vertical_tabs_plugin .list-group-item {
    border-left: 0;
    border-right: 0;
}

.vertical_tabs_plugin .nav-tabs, .list-group {
    border: 0;
}

.vertical_tabs_plugin .list-group-item {
    border: 0;
}

.vertical_tabs_plugin .list-group-item span {
    display: block;
    background: #4bc26c;
    width: 37px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 4px;
}

@media screen and (max-width: 767.98px) {
    .vertical_tabs_plugin .list-group-item span {
        width: 30px;
        height: 30px;
        line-height: 30px;
        left: 7px;
        top: 7px;
    }
}

.vertical_tabs_plugin .list-group-item div {
    display: inline-block;
    text-align: left;
}

.vertical_tabs_plugin .list-group-item div.fa-chevron-right {
    margin-left: 30px;
    visibility: hidden;
    padding-left: 1px;
}

.vertical_tabs_plugin .list-group-item.active div.fa-chevron-right {
    margin-left: 30px;
    visibility: visible;
}

.vertical_tabs_plugin .list-group-item div a {
    color: #30333a;
    text-decoration: none;
}

.vertical_tabs_plugin .list-group-item span i {
    color: #fff;
}

.vertical_tabs_plugin .list-group-item.active,
.vertical_tabs_plugin .list-group-item.active:hover,
.vertical_tabs_plugin .list-group-item.active:focus,
.vertical_tabs_plugin .list-group-item a[aria-expanded="true"]
{
    z-index: 2;
    color: #000;
    background-color: transparent;
    border-color: transparent;
    font-weight: bold;
}

.vertical_tabs_plugin .list-group-item .vertical_tab_title:before {
  display: block;
  content: attr(data-text);
  font-weight: bold;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
}

.vertical_tabs_plugin h2 {
    font-weight: bold;
    font-size: 24px;
}

.vertical_tabs_plugin .accordion a .fa-chevron-down {
    float: right;
    color: #000;
}

.vertical_tabs_plugin .accordion a[aria-expanded="true"] .fa-chevron-down {
    transform: rotate(180deg);
}

.vertical_tabs_plugin .accordion .panel-title {
    color: #000;
}

/*CHARTS STATISTICS*/

#eu_general_numbers .circle {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    background-color: #0e90d2;
}

#eu_general_numbers #main_circle {
    border-radius: 100%;
    width: 250px;
    height: 250px;
    background-color: rgb(32, 50, 95);
    display: inline-block;
    box-shadow: 0 0 10px 2px rgba(32,50,95,0.6);
}

#eu_general_numbers #eu_circle {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    background-color: #0e90d2;
    display: inline-block;
    box-shadow: 0 0 10px 2px rgba(14,144,210,0.6);
}

#eu_general_numbers #main_circle > span {
    position: relative;
    top: 38%;
}

#eu_general_numbers #eu_circle > span,
#eu_general_numbers #eu_greek_circle > span,
#eu_general_numbers #eu_cyrillic_circle > span {
    position: relative;
    top: 34%;
}

#eu_general_numbers #eu_greek_circle {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    background-color: grey;
    display: inline-block;
    box-shadow: 0 0 10px 2px rgba(128,128,128,0.6);
}

#eu_general_numbers #eu_cyrillic_circle {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    background-color: #4bc26c;
    display: inline-block;
    box-shadow: 0 0 10px 2px rgba(75,194,108,0.6);
}

#eu_general_numbers #new_registrations {
    color: #ca1d1a;
}

.meeting_registration div#div_id_email label em {
    font-size: 12px;
    color: #7d7d7d;
}

.meeting_registration .form-checkbox {
    width: 100%;
    margin-bottom: 20px;
}

.meeting_registration ul#id_meetings > li {
    list-style: none
}

.meeting_registration label[for=id_meetings] {
    margin-bottom: 8px;
}

.meeting_registration .checkbox label::before {
    top: 5px;
}

.meeting_registration #div_id_meetings .checkbox {
    margin-left: 25px;
}

.meeting_registration #div_id_meetings .checkbox label::before {
    height: 15px;
    width: 15px;
    top: 7px;
}

.meeting_registration #div_id_terms_agreement.checkbox label::after {
    top: -2px;
}

.meeting_registration #div_id_meetings {
    margin-top: 15px;
}

.meeting_registration .checkbox input[type="checkbox"], .checkbox input[type="radio"] {
    cursor: pointer;
    height: 17px;
    width: 17px;
    top: 7px;
}

.vimeo_event_placeholder {
    background-color:black;
    position: relative;
}

.vimeo_event_alt_text {
    transform: translateY(-50%);
    top: 50%;
    color:white;
    text-align:center;
    position: absolute;
    width: 100%;
    font-weight: bolder;
    padding: 35px;
}


.landing_page {
    background: url('../images/splashes/img_1.jpg') no-repeat center center fixed;
    background-size: cover;
}

.flexslider .slides li:first-child {
    display: block;
}

.flexslider .slides img, .flexslider .slides li a {
    height: calc(26.5vw);
    width: 100%;
    display: block;
}

@media screen and (max-width: 500px){
    .flexslider .slides img, .flexslider .slides li a {
        height: calc(29vw);
        width: 100%;
        display: block;
    }
}

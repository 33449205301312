/** STYLESHEET FOR ALL HEADER / TOP BAR RELATED STYLES **/

header {
    padding: 0 123px 0 0;
    min-height: 50px;
    position: relative;
}

.landing_page header {
    padding: 10px 15px 0 15px;
    min-height: inherit;
    position: relative;
}

.header_area {
    width: 100%;
    background: #20325f;
    top: 0;
    z-index: 10000;
}
.header_area .center_content {
    position: relative;
    transition: .3s background-color;
    z-index: 10000;
}

.modal-open .header_area{
    z-index: 2;
}

.is-sticky .header_area {
    background: rgba(32, 50, 95, 0.9);
}

.navbar-brand {
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar-nav.navbar-right:last-child {
    margin-right: 0;
}

.navbar {
    margin: 0;
    padding-right: 10px;
}

.navbar-collapse {
    border-top: inherit !important;
    box-shadow: none !important;
    padding-left: 0;
    padding-right: 0;
}

.navbar-default {
    background-color: inherit !important;
    border: none !important;
}

.navbar-default .navbar-nav > li {
    border: 0;
}

.navbar-default .navbar-nav > li:first-child {
    border: none;
}

.navbar-default .navbar-nav > li > a {
    font-size: 14px;
    padding-left: 13px;
    padding-right: 13px;
}

.navbar-default .navbar-nav li a {
    text-decoration: none;
}

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #fff !important
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus
{
    color: #fff;
    background-color: #5b89e1;
}

.navbar-default .navbar-nav > .active > a {
    font-weight: 400;
}

.navbar-brand.navbar-responsive.pull-right{
    display: none;
}

nav.navbar.navbar-default.navbar-static-top .col-sm-10.col-xs-12.navbar-collapse.collapse {
    width: 83.333333%
}

.submenu {
    float: initial !important;
    margin-left: 100%;
    background-color: #20325f;
    color: white;
    top: 0;
    margin-top: -5px;
}

.submenu a {
    padding-left: 40px;
    color: white !important;
    display: block;
    width: 100%;
    font-size: 14px !important;
    font-weight: 300 !important;
}

.submenu a:hover,
.submenu a:active {
    background-color: #5b89e1 !important;
}

.navbar-nav > li > .dropdown-menu,
.navbar-nav > li > .dropdown-menu > li > a {
    background-color: #20325f;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: white;
}

.navbar-nav > li > .dropdown-menu > li > a:hover,
.navbar-nav > li > .dropdown-menu > li > a:active{
    background-color: #5b89e1;
}

.dropdown.children.open > .dropdown-menu {
    display: inline;
}

i.menu-icon {
    float: right;
    margin-top: 5px;
}

@media screen and (min-width: 768px){
    /* center the menu*/
    .navigation-top {
        text-align: center;
    }
    .navigation-top > ul {
        float: none;
        display: inline-block;
        margin-bottom: -6px;
    }

    .navigation-top > ul > li {
        display: inline;
    }
}

@media screen and (max-width: 1113px) {

    .landing_page header {
        padding: 10px 130px 0 0;
    }
}

@media screen and (max-width: 950px) {
    .navbar-default .navbar-nav > li > a {
        font-size: 12px;
    }
}

@media screen and (max-width: 920px) {
    .navbar-default .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 870px) {
    .header_area .navbar-default .navbar-nav > li > a {
        font-size: 12px;
    }
}

@media screen and (max-width: 790px) {

    .navbar-default .navbar-nav > li > a {
        padding-top: 7px;
        padding-bottom: 7px;
    }
}

/* BOOTSTRAP XS */
@media screen and (max-width: 767px){

    .nav,
    .navbar-nav,
    .navbar-right {
        float: none;
        margin: 12px 0 0 0 !important;
    }

    .header_area .navbar {
        float: none !important;
    }

    ul.submenu {
        margin-left: 0;
        padding-left: 15px;
        width: 50% !important;
    }

    ul.submenu.in > li > a:hover,
    ul.submenu > li > a,
    ul.submenu.in > li > ul > li > a:hover,
    ul.submenu > li > a,
    .navbar-nav > li > .dropdown-menu > li > a {
        background-color: #20325f !important;
    }

    .navbar-brand.navbar-responsive.pull-right {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .navbar-right {
        display: none;
    }

    nav.navbar.navbar-default.navbar-static-top .col-sm-10.col-xs-12.navbar-collapse.collapse {
        width: inherit;
    }

    li.child.sibling:not(#loop-language) > a, li.child.dropdown.children{
        width: 100%;
    }

    li.child.dropdown.children.open,
    .navbar-default .navbar-nav > .open > a {
        background-color: #20325f;
    }

    li.child.dropdown.children > ul,
    li.child.dropdown.children > a,
    li.child.dropdown.children li.child.dropdown.children {
        width: 100% !important;
    }

    header {
        overflow: inherit;
        padding: 0;
        position: relative;
    }

    .navbar-default .navbar-nav > li > a:hover,
    .navbar-default .navbar-nav > .active > a,
    .navbar-default .navbar-nav > .active > a:hover,
    .navbar-default .navbar-nav > .active > a:focus {
        color: #fff !important;
        background-color: #20325f !important;
        background: none;
    }

    .navbar-default .navbar-toggle,
    .navbar-default .navbar-toggle .icon-bar {
        border-color: #fff;
    }

    .navbar-default .navbar-toggle .icon-bar{
        background-color: #fff;
    }

    .navbar-brand {
        display: none;
    }

    .navbar-brand.navbar-responsive.pull-right{
        display: block;
    }

    .navbar-form {
        border: none;
        border-color: transparent !important;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
        background: none
    }

    .navbar-toggle {
        background: transparent none;
        border: 2px solid transparent;
        border-radius: 50%;
        float: none;
        margin: 7px 0 0 10px;
        padding: 9px 5px;
    }

    .header_area {
        padding: 0;
        background: #20325f;
    }

    .navbar-default .navbar-nav > li {
        border: none
    }

    .navbar-default .navbar-nav > li > a {
        margin: 0 !important;
        padding: 10px;
        font-size: 16px;
        line-height: normal;
    }

    .navbar-default .navbar-nav > li.whois_item a {
        margin: 0;
        position: relative;
    }

    .navbar-default .navbar-nav > li.whois_item a::before,
    .navbar-default .navbar-nav > li.whois_item .click_search {
        left: 10px;
        top: 10px;
    }

    .container > .navbar-header, .container-fluid > .navbar-header,
    .container > .navbar-collapse, .container-fluid > .navbar-collapse {
        margin-left: 0;
        margin-right: 0;
    }

    .navbar-nav > li > .dropdown-menu > li > a,
    .navbar-nav .submenu a,
    .navbar-nav .submenu li {
        color: white !important;
        font-size: 12px !important;
        word-break: break-all;
        white-space: normal;
    }
    .navbar-nav > li > a span.caret{
        margin-left: 15px;
    }

    .navigation-top ul {
        float: inherit;
        width: 100%;
    }

    i.menu-icon {
        float: inherit;
        margin-top: inherit;
        margin-left: 15px;
    }

}

@media screen and (max-width: 440px) {

    i.menu-icon, span.menu-icon {
        float: right;
        margin-top: 5px;
    }
    i.menu-icon {
        margin-right: -5px;
        margin-left: 0;
    }
}

/* Language selector + loop icon */
.language-selector {
    position: absolute;
    right: 0px;
    top: 10px;
    min-width: 130px;
    padding-right: 0;
}

div.header_area .language-selector .selpicker button.btn.dropdown-toggle {
    color: white;
    font-size: 14px;
    box-shadow: none;
}

div.header_area .language-selector ul.dropdown-menu,
div.header_area .language-selector div.dropdown-menu
{
    background-color: #20325f;
}

div.header_area .language-selector ul.dropdown-menu li.selected a,
div.header_area .language-selector div.dropdown-menu li.selected a,
div.header_area .language-selector button.btn.dropdown-toggle:hover,
div.header_area .language-selector button.btn.dropdown-toggle:focus,
div.header_area .language-selector button.btn.dropdown-toggle:active,
div.header_area .language-selector ul.dropdown-menu li a:hover,
div.header_area .language-selector div.dropdown-menu li a:hover
{
    background-color: #5b89e1;
}

div.header_area .language-selector ul.dropdown-menu li a
{
    color: white;
    font-size: 14px;
}

.select_title .btn {
    background: transparent;
}

#search_menu {
    cursor: pointer;
}

#loop-language {
    display: none;
}

.header_area .select_title .btn-default:active,
.header_area .select_title .btn-default.active,
.header_area .select_title .open > .dropdown-toggle.btn-default,
.header_area .select_title .dropdown-menu > li > a:hover,
.header_area .select_title .dropdown-menu > li.selected > a,
.header_area .select_title .btn:hover,
.header_area .select_title .btn {
    background: #20325f;
}

@media screen and (max-width: 767px){
    #loop-language{
        position: relative;
        /*right: 25px;*/
        display: block;
        width: 100%;
        float: left;
        z-index: 3;
    }

    #loop-language div.bootstrap-select {
        /*margin-right: 25px;*/
    }

    #loop-language div.bootstrap-select.open {
        width: min-content !important;
    }

    #loop-language span.loop-link {
        float: left;
    }

    #loop-language form {
        display: block;
    }

    form#language-chooser {
        padding-left: 10px;
    }

    div.header_area #loop-language .selpicker button.btn.dropdown-toggle {
        color: white;
        font-size: 11px;
        box-shadow: none;
    }

    div.header_area #loop-language ul.dropdown-menu,
    div.header_area #loop-language div.dropdown-menu
    {
        background-color: #20325f;
    }

    div.header_area #loop-language ul.dropdown-menu li.selected a
    {
        background-color: #5b89e1;
    }

    div.header_area #loop-language ul.dropdown-menu li a,
    div.landing_page_header #loop-language ul.dropdown-menu li a
    {
        color: white;
        font-size: 12px;
    }
}

/* LOGO */
.logo {
    padding-top: 10px;
}

.landing_page .logo {
    padding-top: 10px;
}

.powered_by {
    padding-top: 15px
}

.powered_by_mobile {
    display: none;
    margin: 0 auto;
    max-width: 148px;
    padding: 20px 0;
}

.header_area #logo-div {
    position: absolute;
    left: 15px;
}

@media screen and (max-width: 767px){
    #logo-div {
        float: none !important
    }

    .landing_page .logo {
        padding: 30px 0 0 0;
        max-width: 73px;
        margin: 0 auto;
        float: none !important;
    }

    .powered_by_mobile {
        display: block;
    }

    .header_area .logo {
        float: none !important;
    }

    .header_area #logo-div {
        position: absolute;
        right: 10px;
        top: 0;
    }

    .header_area .logo {
        position: absolute;
        right: 0px;
    }

    .powered_by {
        display: none;
    }
}
/* TOP WHOIS BAR */
.top-whois-bar input[type="text"] {
    border: none;
    margin-left: 10px;
    font-size: 14px;
    height: 27px;
    width: 300px;
    padding: 3px 3px 3px 5px;
}

.top-whois-bar input[type="submit"] {
    background: #5b89e1;
    border-radius: 0;
    color: #fff;
    border: none;
    font-size: 14px;
    padding: 4px 15px;
}

.top-whois-bar form label {
    color: #fff;
    font-weight: 200;
}

.top-whois-bar form {
    text-align: center;
}

.top-whois-bar {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: -10px;
    margin-right: -10px;
    display: none;
    background: #20325f;
}

@media screen and (max-width: 540px){
    .top-whois-bar form label {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 420px){
    .top-whois-bar form {
        width: 85%;
        margin: 0 auto;
    }
    .top-whois-bar input[type="submit"] {
        margin-top: 10px;
    }

    .top-whois-bar input[type="text"]{
        margin-left: 0;
    }
}

@media screen and (max-width: 335px){
    .top-whois-bar input[type="text"]{
        width: 250px;
    }
}

/* CMS TOOLBAR */
.cms-toolbar-expanded.cms-ready .header_area{
    top: 46px;
}

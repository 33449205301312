/* GENERAL STRUCT */
.footer_area {
    padding: 0 0 0 0;
    margin: auto 0 0 0;
}

.footer_area > .row {
    background: #20325f;
    padding: 34px 0 0 0;
}

#footer {
    position: relative;
    min-height: 160px;
}

.footer-note {
    color: white;
    font-size: 13px;
}

.footer_logo a {
    display: block;
}

.footer-links ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    margin-right: 10px;
}

.footer-links ul li a {
    color: #fff;
    font-size: 13px;
    text-decoration: none;
}

.footer-inquiries ul li a {
    color: #fff;
    font-size: 13px;
    text-decoration: none;
}
#footer .certifications {
   padding-right: 10px;
}

#footer .certifications a {
   margin: 0 0 15px 10px;
}

@media screen and (min-width: 767px) {
    .footer-links ul {
        flex-wrap: wrap;
    }

    .footer-links ul li {
        width: 50%;
    }
}

.landing_page .footer_area > .row {
    background-color: transparent;
}

@media screen and (max-width: 768px){
    .landing_page .footer_area > .row {
        background: #20325f;
    }
}


@media screen and (max-width: 500px) {
    #footer {
        padding-left: 0;
    }
}

/* FOOTER ITEMS (LINK) */

.footer_widget {
    padding: 0 0 40px 0;
}

.footer_widget h3 {
    font-weight: 400;
    color: #fff;
    margin: 0;
    padding: 0 0 5px;
    font-size: 18px;
}

.footer_widget li {
    padding-bottom: 0;
}

.footer_widget li a {
    font-size: 13px;
    color: #fff;
    font-weight: 300;
    line-height: 18px;
    text-decoration: none;
}

#sitemap-link {
    text-decoration: none;
    color: #fff;
}

#sitemap-link:hover {
    color: #5b89e1;
}

@media screen and (max-width: 767px) {

    .footer_widget {
        width: 100%;
        float: none;
        display: inline-table;
    }
}

@media screen and (max-width: 500px) {

    .footer_widget {
        display: block;
        padding-right: 0;
    }
}

/* CONTACT AND FAQ BUTTONS */
.buttons-footer {
    width: 100%;
    display: flex;
    justify-content: end;
}

.buttons-footer a.btn {
    transition: background-color 0.5s ease;
    border-radius: 0;
    border: 2px solid #5b89e1;
    background-color: #20325f;
    margin-right: 10px;
}

.buttons-footer a {
    color: white;
}

a.contact-us {
    min-width: 163px;
}

a.faq {
    min-width: 102px;
}

.buttons-footer a:hover,
.buttons-footer a:active,
.buttons-footer a:focus {
    background-color: #5b89e1;
    color: white;
}

@media screen and (max-width: 870px) and (min-width: 768px) {
    .buttons-footer .btn {
        margin-right: 10px;
    }

    a.contact-us {
        min-width: 143px;
    }

    a.faq {
        min-width: 92px;
    }
}

@media screen and (max-width: 515px) {
    a.contact-us, a.faq {
        width: 92%;
        margin-bottom: 5px;
        margin-right: 10px;
    }
}

/* SOCIAL ICONS */

.social-icon-block {
    float: right;
}

.social-icon-block ul li {
    display: inline;
}

.social-icon-block .social-icon {
    float: left;
    margin-right: 10px;
    margin-bottom: 20px;
    display: inline-block;
    max-width: 41px;
}

@media screen and (max-width: 767px) {
    .buttons-footer {
        margin-top: 10px;
    }

    .social-icon-block.second-social .social-icon {
        max-height: 45px !important;
    }
}

@media screen and (max-width: 767px) {
    .social-icon-block .social-icon {
        width: 35px;
        height: 35px;
    }
}

@media screen and (max-width: 500px) {
    .social-icon-block .social-icon {
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: 380px) {
    .social-icon-block .social-icon {
        margin-right: 8px;
    }
}

@media screen and (max-width: 369px) {
    .social-icon-block {
        width: 125%;
        float: initial;
        /*margin-left: -13%;*/
    }

    .social-icon-block.second-social {
        float: right;
    }
}

@media screen and (max-width: 343px) {
    .social-icon-block {
        margin-left: -13%;
    }
}

/* LOGO */
@media screen and (min-width: 1200px) {
    #footer .footer_logo img {
        max-width: 70%;
    }
}

@media screen and (max-width: 500px) {

    .footer_logo {
        position: relative;
        padding-bottom: 30px;
    }
}
